import { AxiosError, AxiosResponse } from 'axios';
import { getCurrentToken } from 'helpers/hooks/sendCurrentToken';
import { httpClient } from 'helpers/httpClient';
import { NoAnswer } from 'interfaces/Feedback/Feedback';

interface ResponseGeoData extends NoAnswer {}

export const replaceGeo = async ({
  data,
  successCallback,
  errorCallback,
}): Promise<AxiosResponse<ResponseGeoData>> => {
  try {
    const config = {
      headers: getCurrentToken(),
    };

    const response = await httpClient.patch<ResponseGeoData>(
      '/cart_items/geo_change',
      data,
      config
    );

    successCallback(response?.data);
  } catch (e) {
    errorCallback(e.response);

    return e.response as AxiosError<unknown> as never;
  }
};
